/* Tailwind css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "DNFForgedBladeLight";
  font-style: normal;
  font-weight: 300;
  src: url("//cdn.df.nexon.com/img/common/font/DNFForgedBlade-Light.otf")
    format("opentype");
}

@font-face {
  font-family: "DNFForgedBladeNormal";
  font-style: normal;
  font-weight: 500;
  src: url("//cdn.df.nexon.com/img/common/font/DNFForgedBlade-Medium.otf")
    format("opentype");
}

@font-face {
  font-family: "DNFForgedBladeBold";
  font-style: normal;
  font-weight: 700;
  src: url("//cdn.df.nexon.com/img/common/font/DNFForgedBlade-Bold.otf")
    format("opentype");
}

/* custom */
:root {
  --container-height: 100vh;
}

html {
  font-family: "Libre Baskerville", serif;
}

.keep-all {
  word-break: keep-all;
}

/* container */
.section-container {
  width: 100%;
  height: var(--container-height);
}

@media screen and (max-width: 1024px) {
  .section-container:not(#MainSection) {
    height: auto;
  }
}

/* snowFlake CSS */
@-webkit-keyframes snowflakes-fall {
  0% {
    top: -10%;
  }
  100% {
    top: 700px;
  }
}

@-webkit-keyframes snowflakes-shake {
  0%,
  100% {
    -webkit-transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(80px);
  }
}

@keyframes snowflakes-fall {
  0% {
    top: -10%;
  }
  100% {
    top: 700px;
  }
}

@keyframes snowflakes-shake {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(80px);
  }
}

#snowflake {
  -webkit-animation-name: snowflakes-fall, snowflakes-shake;
  -webkit-animation-duration: 15s, 5s, 5s;
  -webkit-animation-timing-function: linear, ease-in-out;
  -webkit-animation-iteration-count: infinite, infinite;
  -webkit-animation-play-state: running, running;
  animation-name: snowflakes-fall, snowflakes-shake;
  animation-duration: 15s, 5s, 5s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
  animation-play-state: running, running;
}

#snowflake:nth-of-type(1) {
  left: 5%;
  -webkit-animation-delay: 8s, 2s;
  animation-delay: 8s, 2s;
}

#snowflake:nth-of-type(2) {
  left: 10%;
  -webkit-animation-delay: 9.5s, 0.5s;
  animation-delay: 9.5s, 0.5s;
}

#snowflake:nth-of-type(3) {
  left: 15%;
  -webkit-animation-delay: 7s, 2s;
  animation-delay: 7s, 2s;
}

#snowflake:nth-of-type(4) {
  left: 20%;
  -webkit-animation-delay: 0s, 3s;
  animation-delay: 0s, 3s;
}

#snowflake:nth-of-type(5) {
  left: 25%;
  -webkit-animation-delay: 4.5s, 3s;
  animation-delay: 4.5s, 3s;
}

#snowflake:nth-of-type(6) {
  left: 30%;
  -webkit-animation-delay: 9s, 2s;
  animation-delay: 9s, 2s;
}

#snowflake:nth-of-type(7) {
  left: 35%;
  -webkit-animation-delay: 6s, 1s;
  animation-delay: 6s, 1s;
}

#snowflake:nth-of-type(8) {
  left: 40%;
  -webkit-animation-delay: 12s, 1s;
  animation-delay: 12s, 1s;
}

#snowflake:nth-of-type(9) {
  left: 45%;
  -webkit-animation-delay: 1s, 1.5s;
  animation-delay: 1s, 1.5s;
}

#snowflake:nth-of-type(10) {
  left: 50%;
  -webkit-animation-delay: 4.5s, 0s;
  animation-delay: 4.5s, 0s;
}

#snowflake:nth-of-type(11) {
  left: 55%;
  -webkit-animation-delay: 5s, 1s;
  animation-delay: 5s, 1s;
}

#snowflake:nth-of-type(12) {
  left: 60%;
  -webkit-animation-delay: 2s, 0.5s;
  animation-delay: 2s, 0.5s;
}

#snowflake:nth-of-type(13) {
  left: 65%;
  -webkit-animation-delay: 6.5s, 3s;
  animation-delay: 6.5s, 3s;
}

#snowflake:nth-of-type(14) {
  left: 70%;
  -webkit-animation-delay: 10s, 2s;
  animation-delay: 10s, 2s;
}

#snowflake:nth-of-type(15) {
  left: 75%;
  -webkit-animation-delay: 5.5s, 5s;
  animation-delay: 5.5s, 5s;
}

#snowflake:nth-of-type(16) {
  left: 80%;
  -webkit-animation-delay: 2.5s, 2s;
  animation-delay: 2.5s, 2s;
}

#snowflake:nth-of-type(17) {
  left: 85%;
  -webkit-animation-delay: 3s, 2.5s;
  animation-delay: 3s, 2.5s;
}

#snowflake:nth-of-type(18) {
  left: 90%;
  -webkit-animation-delay: 11s, 4.5s;
  animation-delay: 11s, 4.5s;
}

#snowflake:nth-of-type(19) {
  left: 90%;
  -webkit-animation-delay: 4s, 1.5s;
  animation-delay: 4s, 1.5s;
}

#snowflake:nth-of-type(20) {
  left: 90%;
  -webkit-animation-delay: 1.5s, 3.5s;
  animation-delay: 1.5s, 3.5s;
}
/* // snowFlake CSS */

/* boxGroup CSS */
#boxGroup:first-child > #whiteKeyBoard:first-child {
  border-left: none;
}

#boxGroupMenu > #whiteKeyBoard:nth-child(7) {
  border-right: none;
}

@media screen and (max-width: 1024px) {
  #boxGroup:nth-child(1),
  #boxGroup:nth-child(2),
  #boxGroup:nth-child(3) {
    display: none;
  }
}
/* // boxGroup CSS */

/* whiteKeyBoard animation */
@keyframes keyboard-wave {
  0% {
    height: 80%;
  }

  100% {
    height: 100%;
  }
}

#whiteKeyBoard {
  -webkit-animation-name: keyboard-wave;
  -webkit-animation-duration: 3.5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: running;
  animation-name: keyboard-wave;
  animation-duration: 3.5s;
  animation-timing-function: linear;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: running;
}

/* box-group-1 */
#whiteKeyBoard:nth-of-type(1) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

#whiteKeyBoard:nth-of-type(2) {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

#whiteKeyBoard:nth-of-type(3) {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

#whiteKeyBoard:nth-of-type(4) {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

#whiteKeyBoard:nth-of-type(5) {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}

#whiteKeyBoard:nth-of-type(6) {
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
}

#whiteKeyBoard:nth-of-type(7) {
  -webkit-animation-delay: 7s;
  animation-delay: 7s;
}

/* // whiteKeyBoard animation */

/* 메인 메뉴 건반 animation */
#animation {
  visibility: hidden;
  transition: color 1.5s, transform 2s;
}

#whiteKeyBoard:hover #animation {
  z-index: 10;
  visibility: visible;
  transform: translateY(-40px);
  color: #416d83;
}
/* // 메인 메뉴 건반 animation */

/* boxGroupMenu media css */
@media screen and (max-width: 768px) {
  #boxGroupMenu #whiteKeyBoard:first-child {
    border-left: none;
  }

  #animation {
    display: none;
  }
}
/* // boxGroupMenu media css */

/* menuBar transition */
#menuBar {
  transition: height 0.5s;
}
/* // menuBar transition */

/* buttonNav transition */
#buttonNav {
  transition: opacity 0.5s ease-in;
}

#menuBar:hover #buttonNav {
  visibility: visible;
  opacity: 1;
  top: 40%;
  color: #416d83;
}

#menuBar:hover #menuBarIcon {
  visibility: hidden;
}

@media screen and (max-width: 768px) {
  #menuBar:hover #menuBarIcon {
    visibility: visible;
  }
}
/* // buttonNav transition */

/* slide-banner */
#slideBanner {
  transform: translateX(33.333333%);
}

#slide {
  opacity: 0;
}

#slideActive {
  opacity: 1;
}

#lpBoard {
  transition: transform 0.5s ease-in-out;
}

#slideActive:hover #lpBoard {
  transform: translateX(-40%);
}

#project {
  pointer-events: none;
}

#projectActive {
  cursor: pointer;
}
/* // slide-banner */

@media screen and (max-width: 500px) {
  #slideBanner {
    width: 700px;
  }
}

/* moreButton transition */
#button {
  transition: transform 0.2s;
}

#button:hover {
  transform: translateY(5px);
}

/* #completeModal */
#completeModal {
  transition: transform 1s;
}
